<template>
  <div class="single-sensor" v-if="loaded">
    <v-row class="mb-4 align-center">
      <v-col cols="auto">
        <h2 v-if="sensorDetails.name">
          <v-icon class="mr-2" medium :color="checkStatus(sensorDetails)">mdi-checkbox-blank-circle</v-icon>
          <span>{{ sensorDetails.name }}</span>
        </h2>
        <h2 v-else-if="sensorDetails.label">
          <v-icon medium :color="checkStatus(sensorDetails)">mdi-checkbox-blank-circle</v-icon>
          <span>{{ sensorDetails.label }}</span>
        </h2>
      </v-col>
      <v-col cols="auto">
        <v-btn :color="siteColor" dark :loading="ackBtn" v-if="sensorDetails.alert" @click="acknowledge">Acknowledge</v-btn>
      </v-col>
      <v-spacer />
      <!-- <v-col cols="auto" v-if="sensorDetails.location">
        <h3>{{ sensorDetails.location.name }}</h3>
      </v-col> -->
      <!-- <v-spacer></v-spacer>
      <v-col>
        <h2 @click="routeTo(sensor2)" v-if="sensor2" class="mb-4 text-right">
          {{ sensor2.name }}
          <v-icon medium :color="sensor2.online ? 'green' : 'red'">mdi-checkbox-blank-circle</v-icon>
        </h2>
      </v-col> -->
    </v-row>
    <Chart v-if="currentData.length" ref="chart" :chart-data="chartdata" :options="otherOptions" />
    <template v-if="deviceType === 'KDX'">
      <v-layout row wrap mt-5 class="kdx">
        <v-flex xs12 md6 pa-2 align-center>
          <v-card color="#2B9EB3" height="100%">
            <v-layout align-center justify-space-around wrap fill-height>
              <v-flex shrink>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.H20Pressure"
                  readonly
                  label="H20 Pressure"
                ></v-switch>
              </v-flex>
              <v-flex shrink>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.C02Pressure"
                  readonly
                  label="C02 Pressure"
                ></v-switch>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs12 md6 pa-2>
          <v-card color="#3EA6B9">
            <v-layout>
              <v-flex xs12>
                <v-layout wrap row>
                  <v-flex offset-xs2 offset-sm3>
                    <v-switch
                      color="#fff"
                      :input-value="latestSensor.leftCompContCoil"
                      readonly
                      label="Left Cont Coil"
                    ></v-switch>
                  </v-flex>
                  <v-flex offset-xs2 offset-sm3>
                    <v-switch
                      color="#fff"
                      :input-value="latestSensor.leftHighPressureCoil"
                      readonly
                      label="Left HP Coil"
                    ></v-switch>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12>
                <v-layout wrap row>
                  <v-flex offset-xs2 offset-sm3>
                    <v-switch
                      color="#fff"
                      :input-value="latestSensor.rightCompContCoil"
                      readonly
                      label="Right Cont Coil"
                    ></v-switch>
                  </v-flex>
                  <v-flex offset-xs2 offset-sm3>
                    <v-switch
                      color="#fff"
                      :input-value="latestSensor.rightHighPressureCoil"
                      readonly
                      label="Right HP Coil"
                    ></v-switch>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout wrap row class="kdx">
        <v-flex xs6 md3 pa-2 order-md-1 order-xs1>
          <v-card color="#55B676">
            <v-layout wrap column>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b1Water"
                  readonly
                  label="B1 Water"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch color="#fff" :input-value="latestSensor.b1BIB" readonly label="B1 BIB"></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch color="#fff" :input-value="latestSensor.b1C02" readonly label="B1 CO2"></v-switch>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs6 md3 pa-2 order-md-2 order-xs2>
          <v-card color="#04a896">
            <v-layout wrap column>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b2Water"
                  readonly
                  label="B2 Water"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch color="#fff" :input-value="latestSensor.b2BIB" readonly label="B2 BIB"></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch color="#fff" :input-value="latestSensor.b2C02" readonly label="B2 CO2"></v-switch>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs6 md3 pa-2 order-md-3 order-xs5>
          <v-card color="#7A81B1">
            <v-layout wrap column>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b3Water"
                  readonly
                  label="B3 Water"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch color="#fff" :input-value="latestSensor.b3BIB" readonly label="B3 BIB"></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch color="#fff" :input-value="latestSensor.b3C02" readonly label="B3 CO2"></v-switch>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs6 md3 pa-2 order-md-4 order-xs6>
          <v-card color="#E56D51">
            <v-layout wrap column>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b4Water"
                  readonly
                  label="B4 Water"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch color="#fff" :input-value="latestSensor.b4BIB" readonly label="B4 BIB"></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch color="#fff" :input-value="latestSensor.b4C02" readonly label="B4 CO2"></v-switch>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs6 md3 pa-2 order-md-5 order-xs3>
          <v-card color="#66BD84">
            <v-layout wrap column>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b1Defrost"
                  readonly
                  label="B1 Defrost"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b1Liquid"
                  readonly
                  label="B1 Liquid"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b1Syrup"
                  readonly
                  label="B1 Syrup"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch color="#fff" :input-value="latestSensor.b1Fill" readonly label="B1 Fill"></v-switch>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs6 md3 pa-2 order-md-6 order-xs4>
          <v-card color="#1AAF9F">
            <v-layout wrap column>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b2Defrost"
                  readonly
                  label="B2 Defrost"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b2Liquid"
                  readonly
                  label="B2 Liquid"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b2Syrup"
                  readonly
                  label="B2 Syrup"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch color="#fff" :input-value="latestSensor.b2Fill" readonly label="B2 Fill"></v-switch>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs6 md3 pa-2 order-md-7 order-xs7>
          <v-card color="#868CB8">
            <v-layout wrap column>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b3Defrost"
                  readonly
                  label="B3 Defrost"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b3Liquid"
                  readonly
                  label="B3 Liquid"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b3Syrup"
                  readonly
                  label="B3 Syrup"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch color="#fff" :input-value="latestSensor.b3Fill" readonly label="B3 Fill"></v-switch>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
        <v-flex xs6 md3 pa-2 order-md-8 order-xs8>
          <v-card color="#E77A60">
            <v-layout wrap column>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b4Defrost"
                  readonly
                  label="B4 Defrost"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b4Liquid"
                  readonly
                  label="B4 Liquid"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch
                  color="#fff"
                  :input-value="latestSensor.b4Syrup"
                  readonly
                  label="B4 Syrup"
                ></v-switch>
              </v-flex>
              <v-flex offset-xs2 offset-sm4>
                <v-switch color="#fff" :input-value="latestSensor.b4Fill" readonly label="B4 Fill"></v-switch>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </template>
    <template v-else-if="deviceType === 'MDX'">
      <v-container fluid class="mt-4">
        <v-row justify="start">
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12">
                <h2 class="mb-2">Data</h2>
                <v-btn class="ma-1" :loading="realtimeButton" @click="realTime">Realtime</v-btn>
                <v-btn
                  class="ma-1"
                  :loading="oneDayButton"
                  @click="getDateReadings(1, 'days')"
                >24 hours</v-btn>
                <v-btn
                  class="ma-1"
                  :loading="threeDayButton"
                  @click="getDateReadings(3, 'days')"
                >3 days</v-btn>
                <v-btn
                  class="ma-1"
                  :loading="sevenDayButton"
                  @click="getDateReadings(7, 'days')"
                >7 days</v-btn>
                <v-btn
                  class="ma-1"
                  :loading="tenDayButton"
                  @click="getDateReadings(10, 'days')"
                >10 days</v-btn>
                <v-btn
                  class="ma-1"
                  :loading="thirtyDayButton"
                  @click="getDateReadings(30, 'days')"
                >30 days</v-btn>
              </v-col>
              <v-col cols="12">
                <v-date-picker
                  min="2020-06-01"
                  :max="(new Date()).toISOString()"
                  :color="siteColor"
                  v-model="dates"
                  range
                  width="310"
                ></v-date-picker>
              </v-col>
              <v-col cols="auto" shrink>
                <download-csv :data="csvData" name="readings.csv">
                  <v-btn :color="siteColor" outlined>
                    <v-icon :color="siteColor" left>mdi-chart-line</v-icon>Export to CSV
                  </v-btn>
                </download-csv>
              </v-col>
              <v-col cols="auto" shrink>
                <v-btn :color="siteColor" outlined @click="exportPDF" :loading="generatePDF">
                  <v-icon :color="siteColor" left >mdi-chart-line</v-icon>Export to PDF
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12">
                <Alarm />
              </v-col>
              <v-col cols="12">
                <Alert />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-else-if="deviceType === 'TDX'">
      <v-container fluid class="mt-4 no-pad">
        <v-row justify="center">
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12">
                <h2 class="mb-2">Data</h2>
                <v-btn class="ma-1" :loading="realtimeButton" @click="realTime">Realtime</v-btn>
                <v-btn
                  class="ma-1"
                  :loading="oneDayButton"
                  @click="getDateReadings(1, 'days')"
                >24 hours</v-btn>
                <v-btn
                  class="ma-1"
                  :loading="threeDayButton"
                  @click="getDateReadings(3, 'days')"
                >3 days</v-btn>
                <v-btn
                  class="ma-1"
                  :loading="sevenDayButton"
                  @click="getDateReadings(7, 'days')"
                >7 days</v-btn>
                <v-btn
                  class="ma-1"
                  :loading="tenDayButton"
                  @click="getDateReadings(10, 'days')"
                >10 days</v-btn>
                <v-btn
                  class="ma-1"
                  :loading="thirtyDayButton"
                  @click="getDateReadings(30, 'days')"
                >30 days</v-btn>
              </v-col>
              <v-col cols="12">
                <v-date-picker
                  min="2020-06-01"
                  :max="(new Date()).toISOString()"
                  :color="siteColor"
                  v-model="dates"
                  range
                  width="310"
                ></v-date-picker>
              </v-col>
              <v-col cols="auto" shrink>
                <download-csv :data="csvData" name="readings.csv">
                  <v-btn :color="siteColor" outlined>
                    <v-icon :color="siteColor" left>mdi-chart-line</v-icon>Export to CSV
                  </v-btn>
                </download-csv>
              </v-col>
              <v-col cols="auto" shrink>
                <v-btn :color="siteColor" outlined @click="exportPDF" :loading="generatePDF">
                  <v-icon :color="siteColor" left>mdi-chart-line</v-icon>Export to PDF
                </v-btn>
              </v-col>
              <!-- <v-col cols="auto" shrink>
                <p v-if="dates.length !== 2">Select two dates to generate a report</p>
                <v-btn :color="siteColor" outlined @click="generateReportFromRange" :disabled="dates.length !== 2">
                  <v-icon :color="siteColor" left>mdi-chart-line</v-icon>Generate Report
                </v-btn>
              </v-col> -->
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12">
                <Alarm />
              </v-col>
              <v-col cols="12">
                <Alert />
              </v-col>
              <!-- <v-col cols="12">
                <Reporting />
              </v-col> -->
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" >
                <Event />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import Chart from "@/components/Chart";
import Alert from "@/components/Alert";
import Alarm from "@/components/Alarm";
import Event from "@/components/Event";
import pdf from "pdfjs"
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
// import Exporter from "vue-chartjs-exporter";
// import Reporting from "@/views/Reporting.vue";
import html2canvas from "html2canvas";
export default {
  name: "device",
  data() {
    return {
      thermapen: "",
      generatePDF: false,
      currSensorDetails: {},
      sensor2: "",
      ackBtn: false,
      loaded: false,
      dates: [],
      realtime: true,
      realtimeButton: false,
      twelveHourButton: false,
      oneDayButton: false,
      twoDayButton: false,
      threeDayButton: false,
      sevenDayButton: false,
      tenDayButton: false,
      fourteenDayButton: false,
      thirtyDayButton: false,
      currentData: [],
      dayReadings: "",
      chartdata: null,
      // chartLow1: 33,
      // chartLow2: 30,
      // chartHigh1: 41,
      // chartHigh2: 44,
      // chartLow1: this.sensorDetails.low,
      // chartLow2: this.sensorDetails.low - 3, 
      // chartHigh1: this.sensorDetails.high,
      // chartHigh2: this.sensorDetails.high + 3,
      sensorData: true,
      kdxOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                max: 200,
              },
            },
          ],
        },
      },
      colors: [
        "#13293D",
        "#006494",
        "#247BA0",
        "#1B98E0",
        "#21897E",
        "#3BA99C",
        "#554971",
        "#63768D",
        "#F08700",
        "#AD5D4E",
        "#353535",
        "#561F37",
        "#72195A",
      ],
      tdxValues: ["min"],
      kdxValues: [
        "b1Temp",
        "b2Temp",
        "b3Temp",
        "b4Temp",
        "compSuctionTemp",
        "compReturnTemp",
        "ambient",
      ],
      mdxValues: [
        "temp1",
        "temp2",
        "temp3",
        "temp4",
        "pressure1",
        "pressure2",
        "pressure3",
        "pressure4",
        "pressure5",
        "pressure6",
        "voltage1",
        "voltage2",
        "voltage3",
      ],
      otherOptions: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          intersect: false,
        },
        scales: {
          xAxes: [
            {
              type: "time",
              time: {
                unit: "minute",
                tooltipFormat: "MMM D, h:mm a",
                stepSize: 5,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                // suggestedMin: 30,
                // suggestedMax: 40,
                // min: 30,
                // max: 50,
                // stepSize: 2
              },
            },
          ],
        },
        legend: {
          display: false,
        },
      },
    };
  },
  components: {
    // Reporting,
    Chart,
    Alert,
    Alarm,
    Event
  },
  async created() {
    let mdx = false;
    // let all = await this.$store.dispatch(
    //   "sensor/getSensorInfo",
    //   this.$route.params.id
    // );
    // let [sensor1, sensor2] = all
    // if (this.$route.params.duelId) {
    //   all.forEach(item => {
    //     if (item.duelId === this.$route.params.duelId) {
    //       sensor1 = item
    //     }
    //     else {
    //       this.sensor2 = item
    //     }
    //   })
    // }
    
    await this.$store.dispatch("sensor/sensorSubscription")
    await this.$store.dispatch("sensor/singleSensor", {
      uuid: this.$route.params.id,
      duelId: this.$route.params.duelId
    });

    this.currSensorDetails = this.sensorDetails

    // if (sensor1.device.model === "mdx") {
    //   await this.$store.dispatch(
    //     "sensor/singleMDXSensor",
    //     this.$route.params.id
    //   );
    // }
    // else {
    //   await this.$store.dispatch("sensor/singleSensor", {
    //     uuid: this.$route.params.id,
    //     duelId: this.$route.params.duelId
    //   });
    // }

    this.setChartVals();
    this.setData();
    this.loaded = true;
  },
  computed: {
    ...mapState("sensor", ["sensor", "deviceType", "sensorDetails", "singleSubscribe", "sensorEvents"]),
    ...mapState("user", ["userDetails"]),
    ...mapGetters("sensor", ["activeAlarms"]),
    chartLow1: {
      get() {
        return this.sensorDetails.low
      },
      set(val) {
        this.sensorDetails.low = val
      }
    },
    chartHigh1: {
      get() {
        return this.sensorDetails.high
      },
      set(val) {
        this.sensorDetails.high = val
      }
    },
    chartLow2: {
      get() {
        return this.sensorDetails.low - 3
      },
      set(val) {
        this.sensorDetails.low2 = val
      }
    },
    chartHigh2: {
      get() {
        return this.sensorDetails.high + 3
      },
      set(val) {
        this.sensorDetails.high2 = val
      }
    },
    latestValues() {
      return this.currentData.map((item) => {
        return {
          val: this.userDetails.degreePref === "Celsius" ? item.minFull.toFixed(this.userDetails.resolution ? this.userDetails.resolution : 1) : item.min,
        };
      });
    },
    values() {
      if (this.deviceType) {
        if (this.deviceType === "MDX") {
          return this.mdxValues;
        } else if (this.deviceType === "KDX") {
          return this.kdxValues;
        } else {
          return this.tdxValues
        }
      } else return [];
    },
    allData() {
      if (!this.values.length) return [];
      return this.values.map((item, i, arr) => {
        return {
          name: item,
          readings: this.currentData.map((cur) => {
            return {
              createdAt: cur.createdAt,
              [item]: cur[item],
            };
          }),
        };
      });
    },
    allDatasets() {
      return this.allData.map((item, i) => {
        return {
          label: `${item.name === "min" ? "temp" : item.name}`,
          borderColor: (context) => {
            if (item.name !== "min") {
              return this.colors[i];
            } else {
              return this.chartGradient(context);
            }
          },
          backgroundColor: (context) => {
            if (item.name !== "min") {
              return this.colors[i];
            } else {
              return this.chartGradient(context);
            }
          },
          radius: 0,
          borderWidth: 2,
          fill: false,
          data: item.readings.map((item2) => {
            return {
              x: new Date(item2.createdAt),
              y: item2[item.name],
            };
          }),
        };
      });
    },
    pickerWidth() {
      return "290px";
    },
    csvData() {
      if (this.deviceType === "TDX") {
        return this.currentData.map((item) => {
          return {
            date: item.createdAt,
            temp: this.userDetails.degreePref === "Celsius" ? item.minFull.toFixed(this.userDetails.resolution ? this.userDetails.resolution : 1) : item.min,
          };
        });
      } else if (this.deviceType === "MDX") {
        return this.currentData.map((item) => {
          return {
            date: item.createdAt,
            temp1: item.temp1,
            temp2: item.temp2,
            temp3: item.temp3,
            temp4: item.temp4,
            pressure1: item.pressure1,
            pressure2: item.pressure2,
            pressure3: item.pressure3,
            pressure4: item.pressure4,
            pressure5: item.pressure5,
            pressure6: item.pressure6,
            voltage1: item.voltage1,
            voltage2: item.voltage2,
            voltage3: item.voltage3,
          };
        });
      } else if (this.deviceType === "KDX") {
        return this.currentData.map((item) => {
          return {
            date: item.createdAt,
            b1Temp: item.b1temp,
            b2temp: item.b2temp,
            b3temp: item.b3temp,
            b4temp: item.b4temp,
            compSuctionTemp: item.compSuctionTemp,
            compReturnTemp: item.compReturnTemp,
            ambient: item.ambient,
          };
        });
      }
    },
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    sensorDefaults() {
      return {
        high: this.sensor[0].sensor.high,
        low: this.sensor[0].sensor.low,
        time: this.sensor[0].sensor.time,
        email: this.sensor[0].sensor.email,
        phone: this.sensor[0].sensor.phone,
      };
    },
    high() {
      return Math.max(
        ...[].concat(
          this.currentData.map((item) => item.min)
        )
      );
    },
    low() {
      return Math.min(
        ...[].concat(
          this.currentData.map((item) => item.min)
        )
      );
    },
    latestSensor() {
      if (this.sensor.length && this.deviceType === "KDX") {
        return Object.entries(this.sensor[this.sensor.length - 1])
          .filter((item) => {
            if ((item[1] === 0 || item[1] === 1) && item[0] !== "deviceId")
              return item;
          })
          .reduce((prev, curr) => {
            prev[curr[0]] = Boolean(curr[1]);
            return prev;
          }, {});
      } else return this.sensor[this.sensor.length - 1];
    },
  },
  watch: {
    thermapen(val) {
      console.log(val)
    },
    dates(val) {
      if (val.length > 1) {
        let [from, to] = val;
        this.getCalendarReadings(
          this.$moment(from).toISOString(),
          this.$moment(to).add(1, "day").toISOString()
        );
      }
    },
    sensor(val) {
      if (this.loaded && val.length <= 20) {
        this.setData();
      } else if (this.loaded) {
        this.setData(val);
      }
    },
    dayReadings(val) {
      // let temps = val.map(item => item.min);
      // let high = Math.max(...temps);
      // let low = Math.min(...temps);
      // let readings = val.filter(item => item.min === high || item.min === low);
      // console.log(readings.length);
      // this.setData(val);
    },
    otherOptions: {
      handler(val) {
        if (this.$refs.chart) {
          this.$refs.chart.renderChart(
            this.chartdata,
            this.otherOptions
            // this.deviceType === "KDX" ? this.kdxOptions : this.otherOptions
          );
        }
      },
      deep: true,
    },
    high(val) {
      this.otherOptions.scales.yAxes[0].ticks.max = val + 1;
      // if ((val - this.low) % 2 === 0) {
      //   this.otherOptions.scales.yAxes[0].ticks.max = val + 2;
      // } else {
      //   this.otherOptions.scales.yAxes[0].ticks.max = val + 1;
      // }
    },
    low(val) {
      this.otherOptions.scales.yAxes[0].ticks.min = val - 1;
    },
  },
  methods: {
    async checkSensor() {
      let device = await navigator.bluetooth.requestDevice({
        // filters: [{ services: ["45544942-4c55-4554-4845-524db87ad700"] }],
        acceptAllDevices: true,
        optionalServices: ["45544942-4c55-4554-4845-524db87ad700"]
      })
      let server = await device.gatt.connect()
      let [primary] = await server.getPrimaryServices("45544942-4c55-4554-4845-524db87ad700")
      let char = await primary.getCharacteristic("45544942-4c55-4554-4845-524db87ad701")
      await char.startNotifications()
      char.addEventListener("characteristicvaluechanged", (event) => {
        this.thermapen = this.convertCelsiusToFahrenheit(Buffer.from(event.target.value.buffer).readFloatLE(0))
      })
    },
    async acknowledge() {
      let [firstAlarm, secondAlarm] = this.sensorDetails.alarms.items.filter(item => item.active)
      if (secondAlarm) secondAlarm = JSON.stringify(secondAlarm)
      this.ackBtn = true
      let event = firstAlarm.events.items[0]
      await this.$store.dispatch("sensor/acknowledge", {
          alarmId: firstAlarm.id,
          eventId: event.id,
          responseBy: `${this.userDetails.first} ${this.userDetails.last}`,
          sensorId: this.sensorDetails.id,
          secondAlarm
      })
      this.ackBtn = false
    },
    convertCelsiusToFahrenheit(temp) {
      return ((temp * 9 / 5) + 32).toFixed(1)
    },
    convertFahrenheitToCelsius(temp) {
      return ((temp - 32) * 5/9).toFixed(this.userDetails.resolution ? this.userDetails.resolution : 0)
    },
    checkStatus(item) {
      if (item.alert) return 'red'
      else if (!item.online || item.leak) return 'blue'
      else if (item.alarm) return 'orange'
      else return '#009006'
    },
    async generateReportFromRange() {
      const startDate = this.dates[0];
      const endDate = this.dates[1];
      if (startDate && endDate) {
        console.log('Generating report for date range: ', startDate, endDate);

        const url = `/device/${this.$route.params.id}/reports/range?start=${startDate}&end=${endDate}`;
        console.log('target url: ', url);
        this.$router.push(url);

      } else {
        console.log('Date range not selected');
      }
    },

    async exportPDF() {
      this.generatePDF = true
      const formatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'short' });
      let sorted = this.chartdata.datasets[0].data.slice().sort((a, b) => a.x - b.x)
      // let start = (sorted[0].x).toLocaleDateString('en-US')
      let start = formatter.format(sorted[0].x)
      // let end = (sorted[sorted.length - 1].x).toLocaleDateString('en-US')
      let end = formatter.format(sorted[sorted.length - 1].x)
      let date = (end === start) ? end : `${start} - ${end}`
      let lineElement = document.getElementById("line-chart");
      let eventElement = document.getElementById("events");
      // let eventCanvas = await html2canvas(eventElement, { scale: 3 });
      let lineCanvas = await html2canvas(lineElement, { scale: 3 });
      var padding = 20;            
      var topMargin = 50;         
      var bottomMargin = 40;       
      var pdfWidth = 594;          
      var pdfHeight = 459;
      var imgData = lineCanvas.toDataURL('image/png');
      // var eventData = eventCanvas.toDataURL('image/png');
      var chartWidth = pdfWidth - padding * 2;
      var chartHeight = lineElement.height * (chartWidth / lineElement.width);
      if (chartHeight + topMargin + bottomMargin > pdfHeight) {
          chartHeight = pdfHeight - topMargin - bottomMargin;
          chartWidth = lineElement.width * (chartHeight / lineElement.height);
      }
      // var eventsWidth = pdfWidth - padding * 2;
      // console.log(eventCanvas.height)
      // var eventsHeight = eventElement.offsetHeight * (eventsWidth / eventElement.width);
      // console.log(eventsHeight)
      // if (eventsHeight + topMargin + bottomMargin > pdfHeight) {
      //     eventsHeight = pdfHeight - topMargin - bottomMargin;
      //     eventsWidth = eventElement.width * (eventsHeight / eventElement.height);
      // }
      var pdf = new jsPDF({
          orientation: 'landscape',
          unit: 'px',
          format: 'letter'
      });
      pdf.setFont('Montserrat-Regular')
      pdf.setFontSize(18)
      let titleText = this.sensorDetails.name;
      let dateText = date;
      let textPadding = 5;
      let titleWidth = pdf.getTextWidth(titleText) + textPadding * 2;
      let dateWidth = pdf.getTextWidth(dateText) + textPadding * 2;
      let titleX = 20; // Left margin
      let titleY = 30; // Vertical position
      let dateX = pdf.internal.pageSize.width - dateWidth - 20; // Right margin
      let dateY = titleY; // Align vertically with title
      // pdf.setFillColor("#525659"); // Light blue for title background
      // pdf.rect(titleX - textPadding, titleY - textPadding - 7, titleWidth, 10 + textPadding, "F"); // Draw filled rectangle

      // pdf.setFillColor("#525659"); // Light peach for date background
      // pdf.rect(dateX - textPadding, dateY - textPadding - 7, dateWidth, 10 + textPadding, "F"); // Draw filled rectangle

      pdf.setTextColor("#0095da");

      // Draw the Title and Date Text
      pdf.text(titleText, titleX, titleY);
      let underlineTitle = titleY + 2; // Position the line slightly below the text (2px offset)
      pdf.setLineWidth(0.5); // Optional: set the thickness of the underline
      // pdf.line(titleX, titleY, titleX + titleWidth, underlineTitle);

      pdf.text(dateText, dateX, dateY);

      // pdf.text(`${this.sensorDetails.name}`,20,30)
      // let stringWidth = pdf.getTextWidth(date)
      // pdf.text(`${date}`,(chartWidth - stringWidth + 20),30)

      pdf.addImage(
            imgData,
            'PNG',
            (pdfWidth - chartWidth) / 2,  // Center the chart horizontally
            topMargin,                    // Top margin
            chartWidth,
            chartHeight
      );

      autoTable(pdf, { html: '#events table',  startY: topMargin + chartHeight + 20 , margin: { left: 20 }, styles: {
        font: 'Montserrat-Regular',
        fontSize: 10
      }, headStyles: {
        fillColor: "#0095da",
        // textColor: [255, 255, 255],
        fontStyle: 'bold',
        fontSize: 11
      }})
      // pdf.addImage(
      //       eventData,
      //       'PNG',
      //       (pdfWidth - eventsWidth) / 2,  // Center events horizontally
      //       topMargin + eventsHeight,                    // Top margin
      //       eventsWidth,
      //       100
      // );
      
      // const exp = new Exporter([line])
      // let chart = await exp.export_pdf()
      // let pdfWidth = chart.internal.pageSize.getWidth() * .9;
      // let imgWidth = pdfWidth;
      // let imgHeight = (eventCanvas.height * pdfWidth) / eventCanvas.width;
      // let imgData = eventCanvas.toDataURL('image/png')
     
      // pdf.setFontSize(16)
      
      // chart.addImage(imgData, 'PNG', 30, 300, imgWidth, imgHeight)
      pdf.save(`${this.sensorDetails.name} (${date}).pdf`);
      this.generatePDF = false
      return

    },
    routeTo(sensor) {
      this.$router.push(`/device/${sensor.uuid}/${sensor.duelId}`)
    },
    checkName(name) {
      if (name === "min") return "Temp";
      else if (name === "min2") return "Temp2";
      else if (name === "temp1") return "Temp1";
      else if (name === "temp2") return "Temp2";
      else if (name === "temp3") return "Temp3";
      else if (name === "temp4") return "Temp4";
      else if (name === "pressure1") return "Pressure1";
      else if (name === "pressure2") return "Pressure2";
      else if (name === "pressure3") return "Pressure3";
      else if (name === "pressure4") return "Pressure4";
      else if (name === "pressure5") return "Pressure5";
      else if (name === "pressure6") return "Pressure6";
      else if (name === "voltage1") return "Voltage1";
      else if (name === "voltage2") return "Voltage2";
      else if (name === "voltage3") return "Voltage3";
      else return "NA";
    },
    setChartVals() {
      this.chartLow1 = this.sensorDetails.low
        ? this.sensorDetails.low + 2
        : -97;
      this.chartLow2 = this.sensorDetails.low ? this.sensorDetails.low : -100;
      this.chartHigh1 = this.sensorDetails.high
        ? this.sensorDetails.high - 2
        : 97;
      this.chartHigh2 = this.sensorDetails.high ? this.sensorDetails.high : 100;
    },
    async realTime() {
      if (!this.realtime) {
        this.dates = [];
        this.realtimeButton = true;
        // await this.$store.dispatch(
        //   "sensor/singleSensor",
        //   this.$route.params.id
        // );
        await this.$store.dispatch("sensor/singleSensor", {
          uuid: this.$route.params.id,
          duelId: this.$route.params.duelId
        });
        this.otherOptions.scales.xAxes[0].time = {
          unit: "minute",
          stepSize: 5,
        };
        // this.setData();
        this.realtime = true;
        this.realtimeButton = false;
      }
    },
    async getCalendarReadings(from, to) {
      this.realtime = false;
      await this.$store.dispatch("sensor/pullReadings", {
        id: this.sensor[0].readingSensorId,
        from,
        to,
      });
      this.otherOptions.scales.xAxes[0].time = {
        unit: "day",
        stepSize: 1,
      };
    },
    async getDateReadings(number, unit) {
      this.dates = [];
      if (number === 12) {
        this.twelveHourButton = true;
      } else if (number === 1) {
        this.oneDayButton = true;
      } else if (number === 2) {
        this.twoDayButton = true;
      } else if (number === 3) {
        this.threeDayButton = true;
      } else if (number === 7) {
        this.sevenDayButton = true;
      } else if (number === 10) {
        this.tenDayButton = true;
      } else if (number === 14) {
        this.fourteenDayButton = true;
      } else if (number === 30) {
        this.thirtyDayButton = true;
      }
      let readings = await this.$store.dispatch("sensor/pullReadings", {
        id: this.sensor[0].readingSensorId,
        number,
        unit,
      });
      if (!readings) {
        this.twelveHourButton = false;
        this.oneDayButton = false;
        this.twoDayButton = false;
        this.threeDayButton = false;
        this.sevenDayButton = false;
        this.tenDayButton = false;
        this.thirtyDayButton = false;
        this.realtime = false;
      } else if (number === 12) {
        this.twelveHourButton = false;
        this.otherOptions.scales.xAxes[0].time = {
          unit: "hour",
          stepSize: 2,
        };
      } else if (number === 1) {
        this.oneDayButton = false;
        this.otherOptions.scales.xAxes[0].time = {
          unit: "hour",
          stepSize: 3,
        };
      } else if (number === 2) {
        this.twoDayButton = false;
        this.otherOptions.scales.xAxes[0].time = {
          unit: "day",
          stepSize: 1,
        };
      } else if (number === 3) {
        this.threeDayButton = false;
        this.otherOptions.scales.xAxes[0].time = {
          unit: "day",
          stepSize: 1,
        };
      } else if (number === 7) {
        this.sevenDayButton = false;
        this.otherOptions.scales.xAxes[0].time = {
          unit: "day",
          stepSize: 1,
        };
      } else if (number === 10) {
        this.tenDayButton = false;
        this.otherOptions.scales.xAxes[0].time = {
          unit: "day",
          stepSize: 1,
        };
      } else if (number === 14) {
        this.fourteenDayButton = false;
        this.otherOptions.scales.xAxes[0].time = {
          unit: "day",
          stepSize: 2,
        };
      } else if (number === 30) {
        this.thirtyDayButton = false;
        this.otherOptions.scales.xAxes[0].time = {
          unit: "day",
          stepSize: 3,
        };
      }
      this.realtime = false;
    },
    colorStop(val) {
      let vals = [];

      // green
      if (this.high < this.chartHigh1) {
        vals.push({
          color: "green",
          value: 0,
        });
      }
      // yellow
      else if (this.low >= this.chartHigh1 && this.high <= this.chartHigh2) {
        vals.push({
          color: "#d8d300",
          value: 0,
        });
      }
      // red
      else if (this.low > this.chartHigh2) {
        vals.push({
          color: "red",
          value: 0,
        });
      }
      // green, yellow and red
      else if (this.low < this.chartHigh1 && this.high >= this.chartHigh2) {
        vals.push(
          {
            color: "green",
            value: 0,
          },
          {
            color: "#d8d300",
            value:
              Math.abs(this.low - this.chartHigh1) / (this.high - this.low),
          },
          {
            color: "red",
            value:
              Math.abs(this.low - this.chartHigh2) / (this.high - this.low),
          }
        );
      }
      // green and yellow
      else if (
        this.low < this.chartHigh1 &&
        this.high >= this.chartHigh1 &&
        this.high < this.chartHigh2
      ) {
        vals.push(
          {
            color: "green",
            value: 0,
          },
          {
            color: "#d8d300",
            value:
              Math.abs(this.low - this.chartHigh1) / (this.high - this.low),
          }
        );
      }
      // yellow and red
      else if (this.low >= this.chartHigh1 && this.high >= this.chartHigh2) {
        vals.push(
          {
            color: "#d8d300",
            value: 0,
          },
          {
            color: "red",
            value:
              Math.abs(this.low - this.chartHigh2) / (this.high - this.low),
          }
        );
      }

      return vals;
    },
    colorCheck(color) {
      if (color === "a" || color === "e") {
        return "red";
      } else if (color === "b" || color === "d") {
        return "#d8d300";
      } else {
        return "green";
      }
    },
    colorStop2(val) {
      let vals = [];
      let currVals = this.currentData.map((item) => this.userDetails.degreePref === "Celsius" ? item.minFull.toFixed(this.userDetails.resolution ? this.userDetails.resolution : 1) : item.min);
      let valTemps = currVals
        .reduce((acc, cur) => {
          // red high
          if (cur > this.chartHigh2) {
            if (!acc.includes("e")) {
              acc.push("e");
            }
          }
          // red low
          else if (cur < this.chartLow2) {
            if (!acc.includes("a")) {
              acc.push("a");
            }
          }
          // yellow high
          else if (cur >= this.chartHigh1 && cur <= this.chartHigh2) {
            if (!acc.includes("d")) {
              acc.push("d");
            }
          }
          // yellow low
          else if (cur >= this.chartLow2 && cur <= this.chartLow1) {
            if (!acc.includes("b")) {
              acc.push("b");
            }
          }
          // green
          else {
            if (!acc.includes("c")) {
              acc.push("c");
            }
          }
          return acc;
        }, [])
        .sort();
      // let colors = valTemps.map(item => this.colorCheck(item));

      if (valTemps.length === 5) {
        vals.push(
          {
            color: "red",
            value: 0,
          },
          { color: "red", value: 1 }
        );
      }

      // // green
      // if (this.high < this.chartHigh1) {
      //   vals.push({
      //     color: "green",
      //     value: 0
      //   });
      //   // console.log("all green");
      // }
      // // yellow high
      // else if (this.low >= this.chartHigh1 && this.high <= this.chartHigh2) {
      //   vals.push({
      //     color: "#d8d300",
      //     value: 0
      //   });
      // }
      // // red
      // else if (this.low > this.chartHigh2) {
      //   vals.push({
      //     color: "red",
      //     value: 0
      //   });
      // }
      // // green, yellow and red
      // else if (this.low < this.chartHigh1 && this.high >= this.chartHigh2) {
      //   vals.push(
      //     {
      //       color: "green",
      //       value: 0
      //     },
      //     {
      //       color: "#d8d300",
      //       value: Math.abs(this.low - this.chartHigh1) / (this.high - this.low)
      //     },
      //     {
      //       color: "red",
      //       value: Math.abs(this.low - this.chartHigh2) / (this.high - this.low)
      //     }
      //   );
      // }
      // // green and yellow
      // else if (
      //   this.low < this.chartHigh1 &&
      //   this.high >= this.chartHigh1 &&
      //   this.high < this.chartHigh2
      // ) {
      //   vals.push(
      //     {
      //       color: "green",
      //       value: 0
      //     },
      //     {
      //       color: "#d8d300",
      //       value: Math.abs(this.low - this.chartHigh1) / (this.high - this.low)
      //     }
      //   );
      // }
      // // yellow and red
      // else if (this.low >= this.chartHigh1 && this.high >= this.chartHigh2) {
      //   vals.push(
      //     {
      //       color: "#d8d300",
      //       value: 0
      //     },
      //     {
      //       color: "red",
      //       value: Math.abs(this.low - this.chartHigh2) / (this.high - this.low)
      //     }
      //   );
      // }
      // console.log(vals);
      return vals;
    },
    chartGradient(context) {
      let gradient = null;
      let width = null;
      let height = null;
      let chartArea = context.chart.chartArea;
      if (!chartArea) {
        return null;
      }
      let chartWidth = chartArea.right - chartArea.left;
      let chartHeight = chartArea.bottom - chartArea.top;
      if (gradient === null || width !== chartWidth || height !== chartHeight) {
        width = chartWidth;
        height = chartHeight;
        let ctx = context.chart.ctx;
        gradient = ctx.createLinearGradient(
          0,
          chartArea.bottom,
          0,
          chartArea.top
        );

        let colors = this.colorStop();
        colors.forEach((item) => {
          gradient.addColorStop(item.value, item.color);
        });
      }
      return gradient;
    },
    getLatestValues() {
      return this.chartdata.datasets.map((item) => {
        return item.data[item.data.length - 1];
      });
    },
    async setData(data) {
      if (this.loaded && !this.sensorDetails.online) this.$store.commit("sensor/setSensorDetails", this.currSensorDetails)
      if (!data) {
        this.currentData = this.sensor.slice(-20);
      } else {
        this.currentData = data;
      }

      if (this.userDetails.degreePref === "Celsius") {
        this.currentData = this.currentData.map((item) => {
          item.min = Number(item.minFull.toFixed(this.userDetails.resolution ? this.userDetails.resolution : 1))
          return item;
        })
      }
      
      // let [latest] = this.currentData.slice(-1);
      this.chartdata = {
        datasets: this.allDatasets,
      };
      // console.log('set chartdata: ', this.currentData);
    }
  },
  async beforeDestroy() {
    await this.$store.dispatch("sensor/emptyLogs");
    if (this.realtime && this.currentData.length) {
      await this.$store.dispatch("sensor/emptySingleSensor");
    }
    if (this.singleSubscribe) {
      await this.$store.dispatch("sensor/unsubscribeSingleSensor")
    }
  },
};
</script>

<style lang="scss">
.single-sensor {
  .kdx {
    .v-input--is-label-active {
      label {
        color: #fff;
      }
    }
  }
}
@media (max-width: 425px) {
  .container.no-pad {
    padding: 0 !important;
  }
}
</style>
